<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <v-card outlined>
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
          >
            Мои Отчеты
            <v-spacer></v-spacer>
            <v-tabs
              class="periodTypes"
              fixed-tabs
              v-model="reportsTabIndex"
              v-if="havePrivPresets && havePrivMyReports"
            >
              <v-tab :disabled="!havePrivMyReports">{{
                havePrivMyReports ? "История Отчетов" : ""
              }}</v-tab>
              <v-tab :disabled="!havePrivPresets">{{
                havePrivPresets ? "Шаблоны" : ""
              }}</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text v-if="curretnTab === 'history'">
            <v-row align="center">
              <v-text-field
                v-model="tableSearchReport"
                label="Поиск по таблице"
                class="mx-4"
              ></v-text-field>
            </v-row>
            <v-data-table
              id="reports-table"
              :items="reports"
              :headers="tableReportsHeaders"
              :loading="loadingReports"
              no-data-text="Отчеты не найдены"
              loading-text="Идет загрузка... Пожалуйста, подождите"
              :search="tableSearchReport"
              :item-class="itemRowBackground"
              :footer-props="{
                'items-per-page-text': 'Строк на странице',
                'items-per-page-all-text': 'Все',
              }"
            >
              <template v-slot:item.actions="{ item }">
                <v-icon
                  class="no-background-hover"
                  small
                  @click="pinItem(item)"
                >
                  {{ item.order == 0 ? "mdi-pin" : "mdi-pin-off" }}
                </v-icon>
                <v-menu offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="no-background-hover"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-dots-vertical
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-icon class="no-background-hover" small>
                        mdi-pencil
                      </v-icon>
                      <v-list-item-content class="pl-2">
                        Переименовать
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="deleteItem(item)">
                      <v-icon class="no-background-hover" small>
                        mdi-delete
                      </v-icon>
                      <v-list-item-content class="pl-2">
                        Удалить
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:item.status="{ item }">
                <v-tooltip top content-class="text-center">
                  <template v-slot:activator="{ on, attrs }">
                    <v-badge
                      dot
                      :color="item.unreading != 0 ? 'orange' : 'transparent'"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        :style="'color:' + getStatusColor(item.status)"
                        >{{ getStatusIcon(item.status) }}</v-icon
                      >
                    </v-badge>
                  </template>
                  <span class="caption">{{ getStatusText(item.status) }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.request.tv_name="{ item }">
                <span v-if="item.request.all_channel == 'true'"
                  >Все телеканалы</span
                >
                <span v-else>{{ item.request.tv_name }}</span>
              </template>
              <template v-slot:item.request.startDate="{ item }">
                <span
                  >{{ formatDate(item.request.startDate, false) }} -
                  {{ formatDate(item.request.endDate, false) }}</span
                >
              </template>
              <template v-slot:item.report="{ item }">
                <span>{{ getReportType(item) }}</span>
                <span
                  style="opacity: 0.5"
                  v-if="
                    item.request.specificReportProperties &&
                    item.request.specificReportProperties.message
                  "
                  ><br />({{
                    item.request.specificReportProperties.message
                  }})</span
                >
              </template>
              <template v-slot:item.created_at="{ item }">
                <span>{{ formatDate(item.created_at) }}</span>
              </template>
              <template v-slot:item.name="{ item }">
                <span
                  v-if="item.status === 'FAILED' || item.status === 'STOPPED'"
                  >{{ item.name || item.uuid }}</span
                >
                <router-link v-else :to="getReportUrl(item)">{{
                  item.name || item.uuid
                }}</router-link>
              </template>
            </v-data-table>
            <report-name-dialog
              :dialog="dialogEdit"
              :reportname="reportNameEdit"
              :uuid="reportUuidEdit"
              @save="updateNameItem"
              @cancel="dialogEdit = false"
            >
            </report-name-dialog>
          </v-card-text>
          <v-card-text v-if="curretnTab === 'presets'">
            <v-row align="center">
              <v-text-field
                v-model="tableSearchPreset"
                label="Поиск по таблице"
                class="mx-4"
              ></v-text-field>

              <v-btn
                class="mr-4 mh-primary-border"
                color="primary"
                elevation="0"
                @click="newPreset"
              >
                Новый шаблон</v-btn
              >
            </v-row>
            <v-data-table
              id="presets-table"
              no-data-text="Шаблоны не найдены"
              :items="presets"
              :headers="tablePresetsHeaders"
              :loading="loadingPresets"
              loading-text="Идет загрузка... Пожалуйста, подождите"
              :search="tableSearchPreset"
              :footer-props="{
                'items-per-page-text': 'Строк на странице',
                'items-per-page-all-text': 'Все',
              }"
            >
              <template v-slot:item.actions="{ item }">
                <!--                <v-icon class="no-background-hover" small @click="pinItem(item)"> mdi-pin </v-icon>-->
                <v-icon
                  small
                  class="no-background-hover mr-2 ml-2"
                  @click="editPreset(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  class="no-background-hover"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:item.filters.presetPeriod="{ item }">
                <span>{{ getFiltersPeriod(item.filters.presetPeriod) }}</span>
              </template>
              <template v-slot:item.report="{ item }">
                <span>{{ getReportType(item.report) }}</span>
              </template>
              <template v-slot:item.schedule="{ item }">
                <v-simple-checkbox
                  v-model="item.schedule"
                  disabled
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.name="{ item }">
                <span>{{ item.name }}</span>
              </template>
            </v-data-table>
            <report-name-dialog
              :dialog="dialogEdit"
              :reportname="reportNameEdit"
              :uuid="reportUuidEdit"
              @save="updateNameItem"
              @cancel="dialogEdit = false"
            >
            </report-name-dialog>
            <v-dialog v-model="dialogNewEditPreset" width="600">
              <create-preset
                ref="createp"
                :id="editPresetId"
                @cancel="dialogNewEditPreset = false"
                @ok="savePreset"
              ></create-preset>
            </v-dialog>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="530px" persistent>
          <v-card>
            <v-card-title class="text-h5"
              >Вы уверены, что хотите удалить {{ helpWord }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Отмена</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Удалить</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ReportNameDialog from "@/components/ReportNameDialog.vue";
import CreatePreset from "@/components/CreatePreset.vue";
import { CONSTS } from "../services/constants";

export default {
  components: { CreatePreset, ReportNameDialog },
  name: "MyReports",
  data: () => ({
    periodsTypes: [
      { id: "today", name: "Сегодня" },
      { id: "yesterday", name: "Вчера" },
      { id: "curentweek", name: "Текущая неделя" },
      { id: "lastweek", name: "Прошлая неделя" },
      { id: "14days", name: "14 дней" },
      { id: "currentmonth", name: "Текущий месяц" },
      { id: "lastmonth", name: "Прошлый месяц" },
      { id: "other", name: "Другой" },
    ],
    reportsTabIndex: 0,
    tableSearchReport: "",
    tableSearchPreset: "",
    currentPin: 0,
    reports: [],
    presets: [],
    editPresetId: -1,
    loadingReports: true,
    loadingPresets: true,
    tablePresetsHeaders: [
      {
        text: "Имя",
        value: "name",
        visible: true,
      },
      {
        text: "Период",
        value: "filters.presetPeriod",
        visible: true,
        width: 150,
      },
      {
        text: "Расписание",
        value: "schedule",
        visible: true,
        width: 150,
      },
      {
        text: "Тип",
        value: "report",
        visible: true,
        width: 150,
      },
      {
        text: "Действия",
        value: "actions",
        visible: true,
        width: 120,
        sortable: false,
      },
    ],
    tableReportsHeaders: [
      {
        text: "",
        value: "status",
        width: 20,
        visible: true,
        sortable: false,
      },
      {
        text: "Имя",
        value: "name",
        width: 250,
        visible: true,
      },
      {
        text: "Тип",
        value: "report",
        visible: true,
        width: 150,
      },
      {
        text: "Город",
        value: "request.geo_name",
        visible: true,
        width: 180,
      },
      {
        text: "Телеканал",
        value: "request.tv_name",
        visible: true,
        width: 180,
      },
      {
        text: "Период",
        value: "request.startDate",
        visible: true,
        width: 150,
      },
      {
        text: "Дата создания",
        value: "created_at",
        visible: true,
        width: 150,
      },
      {
        text: "Действия",
        value: "actions",
        visible: true,
        width: 120,
        align: "end",
        sortable: false,
      },
    ],
    editedIndex: -1,
    dialogNewEditPreset: false,
    dialogDelete: false,
    dialogEdit: false,
    reportNameEdit: "",
    reportUuidEdit: "",
    tabs: ["history", "presets"],
  }),
  methods: {
    itemRowBackground: function (item) {
      return item.order > 0 ? "amber lighten-4" : "";
    },
    async updateReportPin(uuid, pin) {
      let req = new FormData();
      req.append("pin", pin);
      await axios.post("pin/" + uuid, req);
    },
    closeDelete() {
      this.dialogDelete = false;
    },

    deleteItem(item) {
      switch (this.curretnTab) {
        case "history":
          this.editedIndex = this.reports.indexOf(item);
          break;
        case "presets":
          this.editedIndex = this.presets.indexOf(item);
          break;
      }
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      switch (this.curretnTab) {
        case "history":
          await axios.post(`/delete/` + this.reports[this.editedIndex].uuid);
          this.reports.splice(this.editedIndex, 1);
          break;
        case "presets":
          await axios.post(
            `/preset/remove/` + this.presets[this.editedIndex].id
          );
          this.presets.splice(this.editedIndex, 1);
          break;
      }
      this.closeDelete();
    },
    async pinItem(item) {
      let pin = 0;
      let index = this.reports.indexOf(item);
      if (item.order == 0) {
        this.currentPin++;
        pin = this.currentPin;
      }
      await this.updateReportPin(item.uuid, pin);
      item.order = pin;
      if (pin == 0) {
        await this.getHistory();
      } else {
        this.reports.splice(index, 1);
        this.reports.unshift(item);
      }
    },
    editItem(item) {
      this.editedIndex = this.reports.indexOf(item);
      this.reportNameEdit = item.name ?? item.uuid;
      this.reportUuidEdit = item.uuid;
      this.dialogEdit = true;
    },
    editPreset(item) {
      this.editedIndex = this.presets.indexOf(item);
      this.editPresetId = item.id;
      this.dialogNewEditPreset = true;
      setTimeout(() => {
        this.$refs.createp.setData(item);
      }, 1000);
    },
    updateNameItem(name) {
      this.reports[this.editedIndex].name = name;
      this.dialogEdit = false;
      this.editedIndex = -1;
    },
    formatDate(date, withHour = true) {
      if (withHour)
        return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
      else return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    getReportUrl(item) {
      let url = "/";
      switch (item.report) {
        case "tvchannels":
          url += "tv/";
          break;
        case "compare-tv-period":
          url += "cast/compare/period/";
          break;
        case "incomes":
          url += "cast/incomes/";
          break;
        case "tvshows":
          url += "cast/tvshows/";
          break;
        case "compare-tv":
          url += "cast/compare/tv/";
          break;
      }
      url += item.uuid;
      return url;
    },
    async savePreset(json) {
      this.dialogNewEditPreset = false;
      let apiPath = json.id > 0 ? `/presets/${json.id}` : "/preset/new";
      let reqData = new FormData();
      reqData.append("name", json.presetName);
      reqData.append("report", json.reportType);
      reqData.append("filters", JSON.stringify(json.filtersJson));
      reqData.append("schedule", json.schedule ? 1 : 0);
      reqData.append("schedule_params", JSON.stringify(json.scheduleParams));
      await axios.post(apiPath, reqData);
      await this.getPresets();
    },
    newPreset() {
      this.editPresetId = -1;
      this.dialogNewEditPreset = true;
      this.$refs.createp.initForm();
    },
    getReportType(item) {
      let type = "";
      switch (item.report) {
        case "general":
          type += "Общий";
          break;
        case "tvchannels":
          type += "Показатели телеканалов";
          break;
        case "compare-tv-period":
          type += "Анализ программирования";
          break;
        case "incomes":
          type += "Приток-отток";
          break;
        case "tvshows":
          type += "Показатели телепередач";
          break;
        case "compare-tv":
          type += "Сетки вещания";
          break;
      }
      if (item.request.specificReportProperties) {
        if (item.request.specificReportProperties.title) {
          type = item.request.specificReportProperties.title;
        }
      }

      return type;
    },
    getStatusText(status) {
      let text = "в обработке";
      switch (status) {
        case "SUCCESS":
          text = "готово";
          break;
        case "FAILED":
          text = "ошибка";
          break;
        case "STOPPED":
          text = "отменен";
          break;
      }
      return text;
    },
    getStatusIcon(status) {
      let icon = "mdi-timer-sand-full";
      switch (status) {
        case "SUCCESS":
          icon = "mdi-check-circle";
          break;
        case "FAILED":
          icon = "mdi-close-circle";
          break;
      }
      return icon;
    },
    getStatusColor(status) {
      let color = "#FB8C00";
      switch (status) {
        case "SUCCESS":
          color = "#4CAF50";
          break;
        case "FAILED":
          color = "#FF5252";
          break;
      }
      return color;
    },
    getFiltersPeriod(period) {
      return this.periodsTypes.find((el) => {
        return el.id == period;
      }).name;
    },
    async getHistory() {
      let res = await axios.post(`/history`);
      if (res.data) {
        this.reports = res.data;
        this.currentPin = Number(this.reports[0].order);
        this.loadingReports = false;
      }
    },
    async getPresets() {
      let res = await axios.post(`/preset/all`);
      if (res.data) {
        this.presets = res.data.map((el) => {
          return {
            id: el.id,
            name: el.name,
            report: el.report,
            schedule: el.schedule == 1,
            schedule_params: JSON.parse(el.schedule_params),
            filters: JSON.parse(el.filters),
          };
        });
        this.loadingPresets = false;
      }
    },
  },
  mounted() {
    if (this.havePrivPresets) {
      this.reportsTabIndex = 1;
      this.getPresets();
    }
    if (this.havePrivMyReports) {
      this.reportsTabIndex = 0;
      this.getHistory();
    }
    if (!this.havePrivPresets && !this.havePrivMyReports) {
      this.$router.push("/");
    }
  },
  watch: {
    curretnTab() {
      this.editedIndex = -1;
    },
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    havePrivMyReports() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.my_reports_section
      ];
    },
    havePrivPresets() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.presets_sections
      ];
    },
    curretnTab() {
      return this.tabs[this.reportsTabIndex];
    },
    helpWord() {
      let word = "отчет";
      switch (this.curretnTab) {
        case "history":
          word = "отчет";
          break;
        case "presets":
          word = "шаблон";
          break;
      }
      return word;
    },
  },
};
</script>
<style lang="scss">
.no-background-hover::before,
.no-background-hover::after {
  background-color: transparent !important;
}
</style>
